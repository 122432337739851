// File: src/Turnering.js
import React from 'react';
import './App.css';

const Turnering = () => {
    const scheduleGroups = [
      {
        title: 'Oppstart',
        description: 'Alle møter opp, gjennomgang av dagens turnering og oppvarming',
        times: [
            { time: '13:30', title: 'Oppmøte - Alle må være på plass'},
            { time: '13:30 - 14:00', title: 'Intro, lagbilde og gjennomgang'},
            { time: '14:00 - 14:10', title: 'Oppvarming' }
        ],
      },
      {
        title: 'Innledende runder',
        description: 'Gruppene deles inn i fire seksjoner med fire lag i hver seksjon, og hver seksjon spiller tre kamper i løpet av den første timen. Kampene spilles med americano-poeng (1-2-3 i stedet for 15-30-40), med serverotasjon etter fire server. Kampene varer til tiden er ute, og resultatene føres av seksjonsleder. Før hver kamp avgjøres første serv med stein-saks-papir.'
,
        times: [
          { time: '14:10 - 14:25', title: 'Kamp 1' },
          { time: '14:25 - 14:40', title: 'Kamp 2' },
          { time: '14:40 - 14:55', title: 'Kamp 3' },
        ],
      },
      {
        title: 'Vinnerbane',
        description: 'Resultatene fra de innledende rundene bestemmer startplasseringene for vinnerbanespillet, der antall seire trumfer poengforskjell ved likhet. Kampene spilles med tennispoeng (15-30-40) og avgjøres basert på flest games vunnet. Hvis stillingen er lik ved kampens slutt, spilles én avgjørende ball for å kåre en vinner. Vinnerlaget rykker opp én bane etter hver kamp, mens taperlaget rykker ned én bane. Lag på øverste eller nederste bane forblir stående. Første kamp starter med stein-saks-papir, mens senere kamper starter med laget som kommer opp.'
,
        times: [
          { time: '15:00 - 15:13', title: 'Kamp 4' },
          { time: '15:13 - 15:25', title: 'Kamp 5' },
          { time: '15:25 - 15:37', title: 'Kamp 6' },
          { time: '15:37 - 15:50', title: 'Kamp 7' },
        ],
      },
      {
        title: 'Finaler',
        description: 'Finalene spilles mellom lagene som står på øverste bane etter siste kamp, og laget som rykket opp til øverste bane. Kampene følger samme format som vinnerbane, og laget som rykket opp starter. Når tiden er ute, kåres laget med flest games som mester av STAKE CUP 2025.'
,
        times: [
          { time: '15:55 - 16:10', title: 'B-finale' },
          { time: '16:15 - 16:25', title: 'A-finale' },
        ],
      },
    ];
  
    
  return (
    <div className="event-page">
      <section>
        <h2>Årets oppsett</h2>
        <p>
          I fjor brukte vi kun vinnerbaneformatet i to timer, noe som begrenset variasjonen i motstandere.
          I år introduserer vi innledende grupper, slik at hvert lag møter minst fire ulike motstandere i løpet av turneringen.
          Dette gir større variasjon og utfordringer for deltakerne. Les under for reglene, turneringsoppsettet og mer informasjon
        </p>
        <p>Gruppene og seksjonene kan du <a href="/grupper">lese her!</a></p>
      </section>
      <section class="rules-section">
        <h2>Overordnede spilleregler</h2>
        <ul>
        <li>Turneringen arrangeres for det sosiale. Fokus er på glede, samhold og fair play.</li>
        <li>La oss alle bidra til en god tone både på og utenfor banen.</li>
        <li>Ved uenighet om en ball spilles poenget om igjen, uten diskusjon.</li>
        <li>Husk å tilpasse spillet etter nivået på motstanderen – dette er et arrangement for alle ferdighetsnivåer.</li>
        <li>Vi har et tett program, så det er viktig at alle er raske mellom kampene for å holde tidsskjemaet.</li>
        <li>Møt opp i god tid før turneringen starter, slik at vi kan komme i gang til rett tid.</li>
        </ul>
      </section>
      <section>
      <h2>Turneringsprogram</h2>
      {scheduleGroups.map((group, index) => (
        <div key={index} className="schedule-group">
          <h3 className="group-title">{group.title}</h3>
          {group.description && <p className="group-description">{group.description}</p>}
          <ul className="program-list">
            {group.times.map((item, subIndex) => (
              <li key={subIndex} className="schedule-item">
                <span className="program-time">{item.time}</span>
                <span className="program-title">{item.title}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
      </section>
      <section>
        <h2>Premier</h2>
        <p>Gruppe A: Seierspokal i form av vandrepokal. Gruppe A og B: Medaljer til vinnerne i begge grupper.</p>
      </section>
      <section>
  <h2>Diverse informasjon</h2>
  <ul>
    <li>
      <strong>Utstyr - </strong> Deltakerne må ha med eget treningstøy og racket. Racket kan leies i hallen for 50 kr.
    </li>
    <li>
      <strong>Seksjonsledere - </strong> Seksjonslederne annonseres på forhånd, og lagene får informasjon om hvem som leder deres seksjon før spillet starter.
    </li>
    <li>
      <strong>Turneringsleder - </strong> En turneringsleder vil holde oversikt over tid og svare på spørsmål underveis. Denne personen presenteres ved turneringens start.
    </li>
    <li>
      <strong>Garderober - </strong> Det finnes garderober i lokalet. Det vil være en gjeng som dusjer og skifter der borte før de stikker inn til byen - Her det bare å henge seg på om man vil
    </li>
  </ul>
</section>

    </div>
  );
};

export default Turnering;
