// File: src/Map.js
import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './App.css';

const MapPage = () => {
  return (
    <div className="map-page">
      <h1>Rødtvet Lokasjon</h1>
      <p>
        Utforsk vår arena! Klikk på bildet for å zoome inn og se detaljene på Rødtvet.
      </p>
      <Zoom>
        <img
          src="/RODTVEDT.png"
          alt="Rødtvet"
          className="zoomable-image"
        />
      </Zoom>
    </div>
  );
};

export default MapPage;
