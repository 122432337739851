// File: src/Updates.js
import React from 'react';
import { QRCodeSVG } from 'qrcode.react';

const Updates = () => {
  return (
    <div className="event-page">
      <h2>Oppdateringer</h2>
      <section >
        <h3>Matbestilling for banketten</h3>
        <p>Siden vi blir en større gjeng, må vi melde på forhånd inn deres matønsker. Send dette inn via linken her, <a href="https://forms.gle/MPBTjKhahCjCdyv88">https://forms.gle/MPBTjKhahCjCdyv88</a></p>
        <p>Selve menyen kan du finne her på <a href="https://www.heim.no/meny">heim.no</a></p>
      </section>
      <section>
        <h3>Turningsinfo og kart tilgjengelig</h3>
        <p>Det er lagt til nå egen side for turneringsinformasjon og kart - se over i navigasjonsbaren</p>
      </section>
      <section>
        <h3>Betaling av spilleravgift - Frist 17.01</h3>
        <div className="payment-section">
          <div className="payment-text">
            <p>Det er nå åpent for innbetaling av spilleravgiften. Denne kan betales inn til Stian på tlf 97091500. Ta kontakt direkte med meg om du ønsker annen betalingsform enn vipps. Skal du betale for noen andre, vennligst merk det i betalingen!</p>
            <p>Ønsker du å melde deg av? Gjør det enten via Humantix eller kontakt meg direkte asap</p>
          </div>
          <div className="payment-qr">
            <h4>VIPPS - <b>300,- pr spiller</b></h4>
            <a href="https://qr.vipps.no/28/2/01/031/4797091500?v=1" target="_blank" rel="noopener noreferrer">
              <QRCodeSVG value="https://qr.vipps.no/28/2/01/031/4797091500?v=1" />
            </a>
            <p><i>På mobil kan du trykke på QR koden</i></p>
          </div>
        </div>
      </section>
      <section>
        <h3>Bankett er spikret!</h3>
        <p>Årets bankett vil være samme sted som i fjor - nemlig Heim på St.Hanshaugen.</p>
        <p>Her vil dørene åpnes kl 18:00 - der vi får et egen del av lokalet. Middagen blir servert rundt 19. </p>
        <p>Vi må sende inn matbestilling på forhånd, men dette er ennå ikke klart - så jeg vil komme tilbake til dette</p>
      </section>
      <section>
        <h3>Lagoversik og mer info</h3>
        <p>Vil komme tilbake straks om dette!</p>
      </section>
    </div>
  );
};

export default Updates;