import React from "react";
import "./App.css";

const Grupper = () => {
  const groups = [
    {
      groupName: "Gruppe A",
      sections: [
        {
          sectionName: "Seksjon 1",
          teams: [
            ["Stian", "Fredrik"],
            ["Eirik", "Ole"],
            ["Tom Erik", "Ove"],
            ["Marius", "Kim"],
          ],
        },
        {
          sectionName: "Seksjon 2",
          teams: [
            ["Bjarne", "Andreas"],
            ["Petter", "Jonas"],
            ["Henrik", "Morten"],
            ["Jim", "Robin"],
          ],
        },
      ],
    },
    {
      groupName: "Gruppe B",
      sections: [
        {
          sectionName: "Seksjon 1",
          teams: [
            ["Sondre", "Signe"],
            ["Trine", "Lisa"],
            
            ["Astrid", "Markus"],
            ["Jessica, Cecilie","Monica"],
          ],
        },
        {
          sectionName: "Seksjon 2",
          teams: [
            ["Emil", "Charlotte"],
            ["Magnus", "Eline"],
            ["Vendela", "Linn"],
            ["Bendik", "Juliet"],
          ],
        },
      ],
    },
  ];

  return (
    <div className="grupper-page">
      <h1>Grupper</h1>
      <p>
        Under ser du de ulike lagene og hvilke gruppe og seksjon de tilhører. Seksjonene vil spille mot hverandre i de innledene rundene før det åpnes for vinnebane mot alle i samme gruppe
      </p>
      <div className="groups">
        {groups.map((group, groupIndex) => (
          <div key={groupIndex} className="group">
            <h2>{group.groupName}</h2>
            <div className="sections">
              {group.sections.map((section, sectionIndex) => (
                <div key={sectionIndex} className="section">
                  <h3>{section.sectionName}</h3>
                  <ul>
                    {section.teams.map((team, teamIndex) => (
                      <li key={teamIndex}>
                        {team[0]} & {team[1]}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Grupper;
