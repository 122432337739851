// File: src/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    if (isAuthenticated) {
      const redirectPath = location.state?.from || '/main';
      navigate(redirectPath);
    }
  }, [navigate, location]);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password.toLowerCase() === 'vamos') {
      localStorage.setItem('isAuthenticated', 'true'); // Set isAuthenticated in localStorage
      onLogin();
      const redirectPath = location.state?.from || '/main'; // Send til siden brukeren prøvde å nå
      navigate(redirectPath);
    } else {
      alert('Incorrect password');
    }
  };


  return (
    <div className="landing-page">
      <h1>STAKE CUP 2025</h1>
      <form onSubmit={handlePasswordSubmit}>
        <input
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Logg inn</button>
      </form>
    </div>
  );
};

export default Login;
