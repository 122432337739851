// File: src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './Login';
import Updates from './Updates';
import ProtectedRoute from './ProtectedRoute';
import NavMenu from './NavMenu';
import DefaultRedirect from './DefaultRedirect';
import Turnering from './Turnering';
import Grupper from '././Grupper';
import MapPage from './Map';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [iframeVisible, setIframeVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // Sjekk om brukeren er logget inn ved oppstart
  useEffect(() => {
    const loggedIn = localStorage.getItem('isAuthenticated') === 'true';
    setIsAuthenticated(loggedIn);
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
  };

  console.log('isAuthenticated:', isAuthenticated); // Add this line for debugging

  return (
    <Router>
      <div className={`App ${iframeVisible ? 'iframe-open' : ''}`}>
        {/* Navigasjonsmeny vises kun for autentiserte brukere */}
        {isAuthenticated && <NavMenu onLogout={handleLogout} />}

        <Routes>
          {/* Login Page */}
          <Route
            path="/login"
            element={<Login onLogin={handleLogin} />}
          />

          {/* Main Page */}
          <Route
            path="/main"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <div className="event-page">
                  <section>
                    <h2>STAKE CUP 2025 - 25. JANUAR</h2>
                    <p>
                      Velkommen til STAKE CUP 2025 – årets høydepunkt innen padel! Etter en fantastisk
                      første turnering i 2024, er vi klare for en ny runde med aktivitet og moro. STAKE CUP er
                      en turnering som inviterer alle – både nybegynnere og erfarne padelspillere – til en dag
                      fylt med spenning, konkurranse og moro.
                    </p>
                    <img src="/SC2024Wide.jpg" alt="Stake Cup 2024" className="event-image" />
                    <p>
                      Uansett om du spiller for gøy eller går for seieren, er det plass til alle her. Finn frem
                      racketen, knyt skoa og bli med på moroa!
                    </p>
                    <button onClick={() => setIframeVisible(true)}>Meld deg på i dag</button>
                  </section>

                  <section>
                    <h2>CUP MESTERE 2024</h2>
                    <img src="/SC2024Winners.jpg" alt="Stake Cup 2024" className="event-image" />
                    <p>
                      Hvem kan glemme den intense finalen i 2024, der Fredrik og Stian til slutt sto igjen som
                      de første vinnerne av STAKE CUP? Dette var en minneverdig dag for alle som deltok og så på.
                    </p>
                  </section>

                  <section>
                    <h2>PROGRAM</h2>
                    <ul className="program-list">
                      <li><span className="program-time">13:30</span> Oppmøtetid for spillere</li>
                      <li><span className="program-time">14:00</span> Turneringen starter</li>
                      <li><span className="program-time">16:00</span> B-finale</li>
                      <li><span className="program-time">16:15</span> A-finale</li>
                      <li><span className="program-time">17:00</span> Pause for dusj og skift</li>
                      <li><span className="program-time">18:30</span> Banketten åpner på Heim</li>
                      <li><span className="program-time">19:00</span> Middag</li>
                    </ul>
                  </section>

                  <section>
  <h2>TURNERINGEN</h2>
  <p>
    Stake Cup 2025 spilles i klassisk padelformat, med en varighet på 2 timer, etterfulgt av to 15-minutters finaler 
    – én for hver gruppe. Forslaget er at vi deler inn i to grupper basert på påmelding, men dette kan endre seg. Mer info 
    vil komme nærmere turneringsdato. Turneringen spilles i 'vinnerbane'-format, der hvert spill varer i 10–15 minutter 
    før spillerne bytter bane basert på resultatet. Dette gir både høy intensitet og mye variasjon i spillet.
    </p>
    <p>
      Har du ikke makker, vil vi sette opp lag når det nærmer seg, og skulle du finne en etterhver, er det bare å gi beskjed!
    </p>
    <p>
      <i>Ps, det vil bli kåring av beste lag-antrekk/kostyme</i>
    </p>
  
  {/* Location Subsection with Google Maps Iframe */}
  <div className="location-section">
    <div className="location-info">
      <h3>Lokasjon</h3>
      <p><strong>Nordic Padel Rødtvet</strong></p>
      <p>Pottemakerveien 12, Oslo</p>
    </div>
    <iframe
      title="Stake Cup Venue Map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6720.105657922202!2d10.845593426087808!3d59.95040379107908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464171cefd69015d%3A0x4d4d374b58127a7b!2sNordic%20Padel%20%26%20Golf%20Arena%20avd%20Nord!5e0!3m2!1sno!2sno!4v1729878671888!5m2!1sno!2sno"
      width="100%"
      height="300"
      style={{ border: 0, borderRadius: '8px' }}
      allowFullScreen=""
      loading="lazy"
    ></iframe>
  </div>
</section>


  
<section>
  <h2>BANKETT</h2>
  <p>
  Når turneringen er over, samles vi til bankett for å feire dagens prestasjoner med god mat, mye godt i glasset og 
  i veldig godt selskap. Deltakerne vil motta et skjema for matbestilling i forkant, da vi forventer mange påmeldte. 
  Etter middagen fortsetter kvelden på en pub i nærheten, hvor vi runder av STAKE CUP 2025 på best mulig vis.
  </p>

  {/* Bankett Location Subsection */}
  <div className="location-section">
    <div className="location-info">
      <h3>Lokasjon</h3>
      <p><strong>Heim St.Hanshaugen</strong></p>
      <p>Colletts gate 33, 0169 Oslo</p>
    </div>
    <iframe
      title="Bankett Venue Map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1999.3054086327609!2d10.735838176652999!3d59.927074263032004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416e772ce91131%3A0x9f1a078e23154d24!2sHeim%20St.Hanshaugen!5e0!3m2!1sno!2sno!4v1736093853589!5m2!1sno!2sno"
      width="100%"
      height="300"
      style={{ border: 0, borderRadius: '8px' }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</section>

                  <section>
                    <h2>KONTAKT MEG</h2>
                    <p>Har du spørsmål er det bare å ta kontakt med meg - 97091500.</p>
                  </section>

                  {/* Sliding Iframe for RSVP */}
                  {iframeVisible && (
                    <div className={`iframe-container ${iframeVisible ? 'open' : ''}`}>
                      <div className="iframe-content">
                        {loading && <p>Loading registration...</p>}
                        <iframe
                          onLoad={() => setLoading(false)}
                          src="https://events.humanitix.com/stake-cup-2025/tickets?widget=popup&accessCode=VAMOS"
                          title="RSVP"
                        ></iframe>
                      </div>
                      {/* Overlay */}
          {iframeVisible && <div className="iframe-overlay" onClick={() => setIframeVisible(false)}></div>}
                    </div>
                  )}
                </div>
              </ProtectedRoute>
            }
          />

          {/* Updates Page */}
          <Route
            path="/updates"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Updates />
              </ProtectedRoute>
            }
          />

          <Route
            path="/turnering"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Turnering />
              </ProtectedRoute>
            }
          />
          <Route
            path="/grupper"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Grupper />
              </ProtectedRoute>
            }
          />
          <Route
            path="/map"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <MapPage />
              </ProtectedRoute>
            }
          />          

          {/* Default Route */}
          <Route
            path="/"
            element={<DefaultRedirect />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;