import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.css';

const NavMenu = ({ onLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  return (
    <nav className="nav-menu">
      {/* Hamburger Menu Icon */}
      <div className="menu-icon" onClick={toggleMenu}>
        ☰
      </div>

      {/* Navigation Links */}
      <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        {/* Small Screen Menu Title */}
        {isMenuOpen && <li className="menu-title">Menu</li>}
        <li>
          <Link to="/main" onClick={() => setIsMenuOpen(false)}>Hjem</Link>
        </li>
        <li>
          <Link to="/updates" onClick={() => setIsMenuOpen(false)}>Oppdateringer</Link>
        </li>
        <li>
          <Link to="/turnering" onClick={() => setIsMenuOpen(false)}>Turnering</Link>
        </li>
        <li>
          <Link to="/map" onClick={() => setIsMenuOpen(false)}>Kart</Link>
        </li>
        <li>
          <Link to="/grupper" onClick={() => setIsMenuOpen(false)}>Grupper</Link>
        </li>
        {/* Logout Button */}
        <li>
          <button className="logout-button" onClick={() => { onLogout(); setIsMenuOpen(false); }}>Logg ut</button>
        </li>
      </ul>
    </nav>
  );
};

export default NavMenu;
